:root {
    --reach-tabs: 1;
    --tab-header-bg: #777777;
    --tab-panel-bg: #888888;
}

[data-reach-tabs] {
    width: 100%;
}

[data-reach-tab-list],
[data-reach-tab-panels] {
    display: flex;
    width: 100%;
    max-width: 100%;
}
[data-reach-tab-panel] {
    width: 100%;
    max-width: 100%;
}
[data-reach-tab-list] > * {
    flex: 1;
    max-width: 100%;
    /* make buttons fill available height */
}

[data-reach-tab] {
    display: flex;
    align-items: center;
    border: none;
    color: inherit;
    font: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* TAB STATES */

[data-reach-tab]:focus,
[data-reach-tab-panel]:focus {
    outline: Highlight auto 2px; /* Firefox */
    outline: -webkit-focus-ring-color auto 4px !important;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0 !important;
}

[data-reach-tab]:disabled {
    opacity: 0.8;
    cursor: no-drop;
}

[data-reach-tab][data-selected] {
    border-bottom-color: currentColor;
}

[data-orientation='horizontal'] [data-reach-tab-panel] {
    border-top: none;
}

/* VERTICAL */

[data-reach-tabs][data-orientation='vertical'] {
    display: flex;
}

[data-reach-tab-list][aria-orientation='vertical'] {
    min-width: 190px;
}

[data-reach-tab-list][aria-orientation='vertical'] {
    flex-direction: column;
}
