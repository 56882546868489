*,
*::before,
*::after {
    box-sizing: border-box;
    color: currentColor;
}

* {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
    block-size: 100%;
    inline-size: 100%;
}

html:focus-within {
    scroll-behavior: smooth;
}

body {
    text-rendering: optimizeLegibility;
    text-align: left;
    overflow-y: scroll;
    min-block-size: 100%;
    min-inline-size: 100%;
    color: var(--utmb-color-default) !important;
    overflow-x: hidden;

    &.-is-ws {
        color: var(--utmb-color-ws-primary) !important;
    }
}

@media screen and (min-width: 576px) {
    body {
        padding-top: var(--top-bar-height) !important;
    }
}

main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@viewport {
    width: device-width;
}

img {
    border: 0;
    border-style: none;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.no-clipping {
    background-size: contain;
    background-repeat: round;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

[data-reach-skip-link] {
    font-size: rem(16px);
}

[data-reach-skip-link],
.offscreen {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: rem(1px);
    margin: rem(-1px);
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: rem(1px);
}

.unset-styles {
    display: unset;
    margin-bottom: initial;
    color: inherit;
}

[hidden],
template {
    display: none !important;
}

[disabled] {
    cursor: not-allowed;
}

a,
a:visited,
a:hover {
    touch-action: manipulation;
    color: currentColor;
    background-color: transparent;
    text-decoration: none;
    font: inherit;
    text-underline-offset: rem(3px);
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/* SKIP NAV */

[data-reach-skip-nav-link] {
    border: 0;
    clip: rect(0 0 0 0);
    height: rem(1px);
    width: rem(1px);
    margin: rem(-1px);
    padding: 0;
    overflow: hidden;
    position: absolute;
}

[data-reach-skip-nav-link]:focus {
    padding: rem(16px);
    position: fixed;
    z-index: 10000;
    top: rem(32px);
    left: rem(32px);
    background: white;
    width: auto;
    height: auto;
    clip: auto;
}

[data-reach-skip-nav-content] {
    height: 100%;
}

[data-reach-dialog-overlay] {
    z-index: calc(var(--utmb-zindex-max) + 1);
}
