:root {
    --underlay-bg: rgba(255, 255, 255, 0.5);
    --overlay-padding: 0;
}
.underlay {
    z-index: var(--utmb-zindex-max);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--underlay-bg);
    backdrop-filter: blur(10px);
}

.modal:focus {
    outline: none !important;
    outline: -webkit-focusAlgeria-ring-color auto 4px !important;
    box-shadow: 0 0 1000px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 !important;
}

.modal {
    background: var(--popover-bg);
    padding: var(--overlay-padding);
    max-width: 80%;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
    overflow: auto;
}

.modal.is-xs {
    max-width: 48%;
}

.modal-title {
    font-size: 24px;
}

.tray {
    --tray-margin-top: 0;
    --tray-width: 100%;
    --tray-max-width: 100%;
    --tray-min-height: 200px;
    width: var(--tray-width);
    max-width: var(--tray-max-width);
    height: var(--tray-min-height);
    max-height: calc(var(--tray-min-height) - var(--tray-margin-top));
    /*max-height sobrescribe height, pero no min-height */
    position: absolute;
    bottom: 0;
    padding: var(--overlay-padding);
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    transform: translateY(100%);
    outline: none;
    background: var(--popover-bg);
    overflow: hidden;
}

.tray.is-open {
    transform: translateY(0);
}

.tray.is-max {
    height: calc(var(--viewport-height) - var(--tray-margin-top));
    top: var(--tray-margin-top);
}
