@import 'common.scss';

.axeptio_mount {
    #axeptio_main_button {
        bottom: calc(var(--mobile-bar-height) + rem(10px)) !important;

        @include bp(sm) {
            bottom: rem(10px) !important;
        }
    }

    .svgr {
        svg path:not(:last-child) {
            fill: var(--utmb-color-primary) !important;
        }
    }

    div[class^='Elements__Thumbprint'] {
        &:before {
            content: '';
            position: absolute;
            width: rem(200px);
            height: rem(125px);
            top: rem(-70px);
            right: rem(-70px);
            border-radius: 100%;
            background-color: var(--utmb-color-primary);
            box-shadow: rgba(0, 0, 0, 0.2);
        }

        img {
            display: none;
        }
    }

    #axeptio_btn_acceptAll {
        color: var(--utmb-color-primary) !important;

        &:active,
        &:focus {
            box-shadow: var(--utmb-color-primary) 0 0 rem(3px) !important;
        }
    }
}
