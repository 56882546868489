@import 'common/common.scss';

.leaflet-container {
    min-height: rem(500px) !important;
    z-index: var(--utmb-zindex-leaflet);

    @include bp(md) {
        min-height: rem(700px) !important;
    }

    .leaflet-popup {
        color: var(--utmb-color-white) !important;

        .leaflet-popup-content-wrapper {
            color: var(--utmb-color-default) !important;
            background: var(--utmb-color-white) !important;

            .leaflet-popup-content {
                margin: 0;
                width: auto !important;

                p {
                    margin: rem(5px) 0;
                }

                svg {
                    fill: var(--utmb-color-race-primary-darken);
                }
            }
        }
    }

    a.leaflet-popup-close-button {
        padding: rem(10px) rem(25px) 0 0 !important;
    }

    .leaflet-control-zoom {
        flex-direction: column;
        opacity: 0.8;
        transition: opacity 0.5s;

        &:hover {
            opacity: 1;
            transition: opacity 0.5s;
        }
    }

    .leaflet-control-zoom,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        font-weight: 100 !important;
        font-family: var(--utmb-font-futura-light) !important;
        border-top-left-radius: 0 !important;
        line-height: rem(25px) !important;
        font-size: rem(40px) !important;

        &:hover {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 200 !important;
            border-top-left-radius: 0 !important;
            line-height: rem(25px) !important;
        }
    }

    /* -----
LEAFLET LAYERS BUTTONS
----- */

    .leaflet-control {
        margin-top: 0 !important;
    }

    .leaflet-control-layers {
        background-color: var(--utmb-color-primary) !important;
        color: var(--utmb-color-white);
        border: none !important;
        border-radius: 0 !important;
        // opacity: 0.8;
        transition: opacity 0.5s;

        &:hover {
            opacity: 1 !important;
            transition: opacity 0.5s !important;
        }
    }

    .leaflet-control-layers-toggle {
        width: rem(50px) !important;
        height: rem(50px) !important;
        //noinspection CssUnknownTarget
        background-image: url('/map-markers/layers.svg') !important;
    }

    .leaflet-control-layers-base {
        color: var(--utmb-color-white) !important;
        font-size: rem(16px) !important;
        font-family: 'Oxanium' !important;
        cursor: pointer !important;
    }

    .leaflet-control-layers-selector:after {
        width: rem(15px);
        height: rem(15px);
        border-radius: rem(15px);
        top: rem(-2px);
        left: rem(-1px);
        position: relative;
        background-color: var(--utmb-color-white);
        content: '';
        display: inline-block;
        visibility: visible;
        border: rem(2px) solid var(--utmb-color-default);
    }

    .leaflet-control-layers-selector:checked:after {
        width: rem(15px);
        height: rem(15px);
        border-radius: rem(15px);
        top: rem(-2px);
        left: rem(-1px);
        position: relative;
        background-color: var(--utmb-color-secondary);
        content: '';
        display: inline-block;
        visibility: visible;
        border: rem(2px) solid var(--utmb-color-default);
    }
}

/* -----
LEAFLET ZOOM BUTTONS
----- */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    color: white !important;
    width: rem(50px) !important;
    height: rem(50px) !important;
    font-size: rem(35px) !important;
    padding: rem(10px);
    border-radius: 0 !important;
}

.leaflet-top {
    background-color: transparent;
}

.leaflet-control-zoom-in {
    background-color: var(--utmb-color-primary) !important;
}

.leaflet-control-zoom-out {
    background-color: var(--utmb-color-secondary) !important;
}

.leaflet-top.leaflet-right {
    top: 50% !important;
    transform: translate(0%, -50%);
    right: rem(-10px) !important;
    z-index: 400 !important;
}

/* -----
LEAFLET FULLSCREEN BUTTONS
----- */

.leaflet-control-fullscreen {
    background-color: var(--utmb-color-secondary) !important;
    border: none !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.leaflet-control-fullscreen-button {
    background-color: var(--utmb-color-secondary) !important;
    width: rem(50px) !important;
    height: rem(50px) !important;
    //noinspection CssUnknownTarget
    content: url('/map-markers/fullsize-grow.svg');
    border-radius: 0 !important;
    opacity: 1 !important;
    transition: opacity 0.5s !important;

    &:hover {
        opacity: 1 !important;
        transition: opacity 0.5s !important;
    }

    &:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 200 !important;
        border-top-left-radius: 0 !important;
        line-height: rem(25px) !important;
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
    //noinspection CssUnknownTarget
    content: url('/map-markers/fullsize-reduce.svg') !important;
}

/* Maps button when race color is light*/
.lightbg {
    &.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
        //noinspection CssUnknownTarget
        content: url('/map-markers/fullsize-reduce-dark.svg') !important;
    }
    & .leaflet-control-fullscreen-button {
        //noinspection CssUnknownTarget
        content: url('/map-markers/fullsize-grow-dark.svg') !important;
    }
}

/* Maps button when site primary color is light*/
.lightprimbg {
    & .leaflet-control-zoom-in {
        color: var(--utmb-color-ws-primary) !important;
    }
    & .leaflet-control-layers-toggle {
        //noinspection CssUnknownTarget
        background-image: url(/map-markers/layers-dark.svg) !important;
    }
    &.leaflet-container .leaflet-control-layers-base {
        color: var(--utmb-color-ws-primary) !important;
    }
}

/* -----
LEAFLET POPUP
----- */

.leaflet-popup-close-button {
    font-size: rem(32px) !important;
    font-family: var(--utmb-font-futura-light) !important;
    top: rem(15px) !important;
    right: rem(15px) !important;
    color: var(--utmb-color-default) !important;
}

.leaflet-marker-label {
    width: max-content !important;
    height: max-content !important;
    color: var(--utmb-color-white) !important;
}

.leaflet-marker-shadow {
    -webkit-filter: drop-shadow(rem(2px) rem(2px) rem(3px) rgba(0, 0, 0, 0.5));
    filter: drop-shadow(rem(2px) rem(2px) rem(3px) rgba(0, 0, 0, 0.5));
}
