@use 'sass:map';
@use 'sass:string';

/* Breakpoints */

$breakpoints: (
    xxs: 0px,
    xs: 400px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

:export {
    bp: {
        @each $name, $size in $breakpoints {
            #{$name}: $size;
        }
    }
}
