@import './common/common.scss';

.utmb_container {
    width: 100%;
    overflow: hidden;

    @include bp(md) {
        width: 100%;
        max-width: rem(1440px);
        margin: 0 auto;
        overflow: unset;
    }
}

.utmb_container_v2 {
    width: 100%;
    overflow: hidden;

    @include bp(md) {
        width: 100%;
        max-width: rem(1200px) !important;
        margin: 0 auto;
        overflow: unset;
    }
}

.utmb_container_masonry {
    width: 100%;
    overflow: hidden;

    @include bp(md) {
        width: 100%;
        // 30px more because of the 15px gutter in each side of the masonry
        max-width: rem(1230px);
        margin: 0 auto;
        overflow: unset;
    }
}

.utmb_container_mob_pad {
    width: 100%;
    padding: 0 2rem;
    overflow: hidden;

    @include bp(lg) {
        padding: 0 4rem !important;
    }

    @include bp(xl) {
        padding: 0 4rem !important;
    }

    // need padding e.g. for Footer on tablets
    @include bp(md) {
        padding: 0;
        width: 100%;
        max-width: rem(1440px) !important;
        margin: 0 auto;
        overflow: hidden;
    }
}

.utmb_container_v2_mob_pad {
    width: 100%;
    padding: 0 2rem;
    overflow: hidden;

    @include bp(lg) {
        padding: 0 4rem !important;
    }

    @include bp(lg) {
        padding: 0 4rem !important;
    }

    @include bp(md) {
        padding: 0;
        width: 100%;
        max-width: rem(1200px) !important;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (orientation: landscape) {
    .portrait-only {
        display: none !important;
    }
}

@media (orientation: portrait) {
    .landscape-only {
        display: none !important;
    }
}
