@import 'common/common.scss';

.sticky_player {
    position: fixed;
    bottom: 50px;
    right: 0px;
    z-index: 1002;
    padding: 2px;

    width:215px;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;

    background: linear-gradient(90deg,#000d44,#f42525);

    &:hover {
        color: white;
        text-decoration: underline;
    }
    .sticky_title {
        position: relative;
        padding: 5px 0;
        text-transform: uppercase;
        width: 100%;
        .close {
            position: absolute;
            right: 2px;
            top:2px;
            background: transparent;
            border: 1px solid black;
            cursor: pointer;
            &:hover {
                background-color: #000d44;
            }
        }
    }
    iframe {
        border: none;
        width: 208px;
        height: 117px;
    }

    .sticky_title .close, .sticky_title .close svg {
        width: 18px;
        height:18px;
        margin: 0;
        padding:0;
        display: flex;
    }

    @include bp(md) {
        width:340px;
        min-height: 220px;
        bottom: 0px;
        iframe {
            width: 336px;
            height: 189px;
        }

        .sticky_title .close, .sticky_title .close svg {
            width: 14px;
            height:14px;
            margin: 0;
            padding:0;
            display: flex;
        }
    }

    @include bp(xxl) {
        width:410px;
        min-height: 250px;
        bottom: 0px;
        iframe {
            width: 400px;
            height: 225px;
        }
    }
}
