@import '../../styles/common/common.scss';

/* -------------------------------- */

/* Base Styles for Error Message */
.error-message-row {
    justify-content: center;
    align-items: center;
}

.input-message-default {
    display: block;
    width: 100%;
    font-size: rem(16px);
    text-align: left;
    margin: rem(10px) 0;
    padding: rem(25px) rem(20px);
    opacity: 0;
    transform: scale(0.7);
    animation: zoomIn 1s forwards;

    @include bp(md) {
        padding: rem(13px) rem(20px);
    }
}

/* -------------------------------- */

/* Variants */
.error-message {
    color: var(--utmb-color-status-error) !important;
    background-color: rgba(var(--utmb-color-status-error-rgb), 0.1) !important;
}

.warning-message {
    color: var(--utmb-color-status-warning) !important;
    animation: zoomInOut 4s;
}

.success-message {
    color: var(--utmb-color-status-success) !important;
    animation: zoomInOut 4s;
}

.error-hide {
    animation: zoomInOut 4s;
}

/* -------------------------------- */

/* Error Icon */
.error-icon {
    width: rem(20px);
    height: auto;
    margin-right: rem(10px);
}

/* -------------------------------- */

/* Error Message */
.error-message-text {
    margin-top: rem(4px);
}
