@import 'common/common.scss';

/* -------------------------------- */

/* Global Styles */

/* -------------------------------- */

* {
    /* outline: rem(0.5px) red solid; */

    @include bp(max md) {
        outline: none !important;
    }
}

:root {
    --font-size-percent: 62.5%;
    --vh: 1vh;
    --reach-skip-nav: 1;

    /* Default Font Color */
    --utmb-color-default: #41505c;

    --utmb-color-ws-primary: #000d44;
    --utmb-color-ws-primary-0: rgba(0, 13, 68, 0);
    --utmb-color-ws-primary-50: rgba(0, 13, 68, 0.5);
    --utmb-color-ws-primary-100: rgba(0, 13, 68, 1);
    --utmb-color-ws-secondary: #00dbff;
    --utmb-color-ws-secondary-0: rgba(0, 219, 255, 0);
    --utmb-color-ws-secondary-100: rgba(0, 219, 255, 1);
    --utmb-color-ws-virtual-club: #dede27;
    --utmb-color-ws-virtual-club-0: rgba(222, 222, 39, 0);
    --utmb-color-ws-virtual-club-100: rgba(222, 222, 39, 1);
    --utmb-color-vc: #dede27;
    --utmb-color-transparent: transparent;

    --utmb-color-primary: #000d44;
    --utmb-color-secondary: #00dbff;

    --utmb-color-blue: #000d44;
    --utmb-color-teal: #00dbff;

    /* Race Categories */
    --utmb-color-category-general: #00dbff;
    --utmb-color-category-20k: #ffff00;
    --utmb-color-category-50k: #fc5a00;
    --utmb-color-category-100k: #7ddf65;
    --utmb-color-category-100m: #f42525;

    /* Basic Colors */
    --utmb-color-white: #ffffff;
    --utmb-color-black: #000000;
    --utmb-color-background: #f6f7f8;
    --utmb-color-background-darker: #f5f5f5;

    --utmb-color-light-grey: #fafafa;
    --utmb-color-gray: #ecedee;
    --utmb-color-grey: #ecedee;
    --utmb-color-grey-0: rgba(236, 237, 238, 0);
    --utmb-color-grey-100: rgba(236, 237, 238, 100);
    --utmb-color-gray-hover: #e2e2e2;

    --utmb-color-blue-light: #9da7af;
    --utmb-color-blue-dark: #20272d;

    /* Borders */
    --utmb-color-border-gray: #c9ced1;
    --utmb-color-border-light-gray: rgba(151, 151, 151, 0.2);
    --utmb-color-border-darked-gray: rgba(151, 151, 151, 0.6);

    --utmb-color-blend: #d2d2d2;

    /* Component Specific */
    --utmb-color-white-light: #f9f9f9;
    /* table_body */
    --utmb-color-white-light-hover: #f1f1f1;
    /* table_row */

    /* Extended Colors */
    --utmb-color-red: #e20613;
    --utmb-color-blue: #008dfa;
    --utmb-color-orange: #f59c00;
    --utmb-color-green: #76b72a;
    --utmb-color-green-light: #a7ff90;

    /* Statuses */
    --utmb-color-status-error: rgb(209, 69, 99);
    --utmb-color-status-error-rgb: 209, 69, 99;

    --utmb-color-status-success: rgb(6, 121, 6);
    --utmb-color-status-warning: rgb(201, 130, 0);

    /* BW Variants */
    --utmb-color-white-0: rgba(255, 255, 255, 0);
    --utmb-color-white-20: rgba(255, 255, 255, 0.2);
    --utmb-color-white-40: rgba(255, 255, 255, 0.4);
    --utmb-color-white-60: rgba(255, 255, 255, 0.6);
    --utmb-color-white-80: rgba(255, 255, 255, 0.8);
    --utmb-color-white-100: rgba(255, 255, 255, 1);
    --utmb-color-black-20: rgba(0, 0, 0, 0.2);
    --utmb-color-black-40: rgba(0, 0, 0, 0.2);
    --utmb-color-black-60: rgba(0, 0, 0, 0.2);
    --utmb-color-black-80: rgba(0, 0, 0, 0.2);

    /* Stone Allocation Backgrounds */
    --utmb-color-allocations-dark: rgba(0 219 255 / 0.15);
    --utmb-color-allocations-light: #fff;

    /* Leaflet */
    --utmb-color-leaflet-marker-color: var(--utmb-color-white);
    --utmb-color-leaflet-marker-bg-color: var(--utmb-color-primary);

    --quiet: transparent;
    --good: var(--utmb-color-white);
    --bad: #222222;

    /* z-index Management */
    --utmb-zindex-max: 999;
    /* reserved for <Overlay/> */
    --utmb-zindex-over-nav: 955;
    --utmb-zindex-bottom-nav: 1000;
    /* reserved for <MobileBottomNav/> */
    --utmb-zindex-top-nav: 900;
    /* reserved for <TopNav/> */
    --utmb-zindex-nav: 899;
    /* reserved for <DesktopNavigation/> */
    --utmb-zindex-leaflet: 100;

    /* Fonts */
    --utmb-font-futura: 'Futura PT';
    --utmb-font-futura-book: 'Futura PT Book';
    --utmb-font-futura-bold: 'Futura PT Bold';
    --utmb-font-futura-heavy: 'Futura PT Heavy';
    --utmb-font-futura-light: 'Futura PT Light';
    --utmb-font-oxanium: 'Oxanium';

    /* shadows */
    --utmb-box-shadow: 0 0 #{rem(15px)} rgba(0, 0, 0, 0.5);
    --utmb-box-shadow-hover: 0 0 #{rem(15px)} rgba(0, 0, 0, 0.7);
    --utmb-shadows-nav: 0 0 #{rem(15px)} rgba(0, 0, 0, 0.2);
    --utmb-filters-dropdowns: 0 0 #{rem(15px)} rgba(0, 0, 0, 0.2);
    --utmb-text-shadows: 0 0 #{rem(4px)} rgba(23, 23, 23, 0.2);

    /* image filter */
    --utmb-filter-bgimg: brightness(0.6);

    /* top bar */
    --top-bar-height: #{rem(40px)};

    /* Menu */
    --menu-height: #{rem(80px)};
    --menu-sticky-height: #{rem(72px)};
    --menu-sticky-height-simple: #{rem(76px)};
    --mobile-bar-height: #{rem(53px)};

    /* Components unhook */
    --component-unhook-height: #{rem(50px)};
    --component-unhook-height-small: #{rem(10px)};

    /* Page header min height */
    --page-header-min-height-mobile: #{rem(580px)};
    --page-header-min-height-desktop: #{rem(800px)};

    /* Partners menu */
    --partners-menu-container-height-mobile: #{rem(55px)};

    /* bootstrap */
    --bs-gutter-x: #{rem($gutter-x)};

    /* Modal */
    --modal-overlay-background: rgba(0, 0, 0, 0.33);
}

@media screen and (prefers-color-scheme: dark) {
    :root {
        --good: var(--utmb-color-black);
    }
}

html {
    font-size: var(--font-size-percent);

    @media (-webkit-min-device-pixel-ratio: 1.1) and (max-resolution: 107dpi) {
        font-size: calc(var(--font-size-percent) / 1.1);
    }

    @media (-webkit-min-device-pixel-ratio: 1.25) and (max-resolution: 120dpi) {
        font-size: calc(var(--font-size-percent) / 1.25);
    }

    @media (-webkit-min-device-pixel-ratio: 1.3) and (max-resolution: 191dpi) {
        font-size: calc(var(--font-size-percent) / 1.3);
    }

    @media (-webkit-min-device-pixel-ratio: 1.5) and (max-resolution: 144dpi) {
        font-size: calc(var(--font-size-percent) / 1.5);
    }

    @media (-webkit-min-device-pixel-ratio: 1.75) and (max-resolution: 168dpi) {
        font-size: calc(var(--font-size-percent) / 1.75);
    }
}

body {

    --scrollbar-width: calc(100vw - 100%);
    
    font-size: rem(16px);
    font-family: sans-serif;

    &.-locked {
        overflow: hidden;
    }

    .react-confirm-alert-overlay {
        background-color: var(--modal-overlay-background);
        z-index: var(--utmb-zindex-max);

        .react-confirm-alert {
            &-body {
                width: rem(500px);
                font-family: var(--utmb-font-futura-book);

                h1 {
                    margin-bottom: rem(15px);
                }
            }

            &-button-group {
                justify-content: flex-end;
                column-gap: rem(20px);

                button {
                    margin: 0;
                    font-family: var(--utmb-font-futura-book);
                    font-size: rem(16px);
                    border-radius: 0;
                    background-color: var(--utmb-color-primary);
                    box-shadow: var(--utmb-box-shadow);
                }
            }
        }
    }
}

/* -------------------------------- */
/*     max-width avoid overflow     */
/* -------------------------------- */
iframe {
    max-width: 100%;
}

/* -------------------------------- */

/* -------------------------------- */

.no-overflow {
    overflow: hidden !important;
}

.ls-button {
    letter-spacing: rem(2px);
}

/* -------------------------------- */

/* Opacity */

.op-1 {
    opacity: 0.1;
}

.op-2 {
    opacity: 0.2;
}

.op-3 {
    opacity: 0.3;
}

.op-4 {
    opacity: 0.4;
}

.op-5 {
    opacity: 0.5;
}

.op-6 {
    opacity: 0.6;
}

.op-7 {
    opacity: 0.7;
}

.op-8 {
    opacity: 0.8;
}

.op-9 {
    opacity: 0.9;
}

.hero-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* -------------------------------- */

/* Content Containers */

.ms_ps_content,
.race_presentation_content {
    background-color: var(--utmb-color-background);
    overflow: auto;
}

.race_presentation_content {
    position: relative;
}

.bg_overlay_container {
    background-color: transparent;
    overflow: auto;
}

.dark_bg_placeholder {
    min-height: 60vh;
    background: #000d44;
}

.dark_bg_placeholder_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

/* -------------------------------- */

/* CHECKBOX */
.custom-checkbox {
    /* [data-reach-mixed-checkbox]  */
    --checkbox-size: rem(19px);
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    position: relative;
    cursor: pointer;
}

.custom-checkbox:after {
    content: '';
    --checkbox-size: rem(19px);
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    position: absolute;
    background-color: grey;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    speak: none;
    z-index: 2;
}

.custom-checkbox[aria-checked='true']:after {
    background-color: red;
}

/* [data-reach-mixed-checkbox][aria-checked='false']:after  {
           filter:brightness(1);

}
[data-reach-mixed-checkbox][data-state='mixed']:after  {
    filter:brightness(2);
} */

/* POPOVER */
[data-reach-listbox-popover] {
    outline: none !important;
    border: none !important;
    box-shadow: var(--utmb-filters-dropdowns);
    z-index: 100;
}

[data-reach-listbox-popover] [data-reach-listbox-option][data-current-nav] {
    color: var(--utmb-color-default);
}

.spinner {
    display: block;
    position: absolute;
    top: calc(50% - rem(25px));
    left: calc(50% - rem(25px));
    animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    width: rem(50px);
    height: rem(50px);
    border-radius: 50%;
    background-color: #41505c;
}

.link-underline {
    position: relative;

    @include hover-focus {
        outline: none !important;

        &:before {
            width: 100%;
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: rem(2px);
        background-color: var(--utmb-color-ws-secondary);
        transition: width 0.3s;
    }
}

@keyframes spinner {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.react-datepicker-popper {
    z-index: 101 !important;
}

.mktoHtmlText {
    > div {
        padding-left: 0 !important;
    }
}
.mktoForm {
    max-width: 100% !important;
    width: 100% !important;
}
.mktoForm div,
.mktoForm span,
.mktoForm label,
.mktoForm p {
    max-width: 100% !important;
}

.mktoForm .mktoButton, .actions input.hs-button {
    background-color: var(--utmb-color-primary) !important;
    background-image: none !important;
    border: none !important;
    font-size: 1.6rem !important;
    font-family: "Futura PT Heavy" !important;
    font-weight: normal;
    text-transform: uppercase !important;
    padding: 2.5rem 3rem !important;
    letter-spacing: 0.1rem;
    border-radius: 0;
    border:0;
    text-shadow: none;
    box-shadow: none;
    color: white;
    &:hover {
        font-weight: bold;
    }
}

#onetrust-banner-sdk.ot-bnr-w-logo .ot-bnr-logo {
    width: rem(120px) !important;
}

#ot-sdk-btn-floating.ot-floating-button{

    bottom: 90px !important;
    left: 10px !important;

    @include bp(lg) {

        bottom: 10px !important;
        left: 10px !important;
    }
}