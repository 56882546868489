.embla__viewport {
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    width: 100%;
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
}
/*@media (min-width: 768px) {
    .embla__container {
        justify-content: center;
    }
}*/
.embla__slide {
    flex: 0 0 80%;
}

.slider-gap .embla__slide:not(:last-of-type) {
    --slide-gap: 10px;
    margin-right: var(--slide-gap);
}

@media (orientation: landscape) {
    .embla__slide {
        flex: 0 0 34%;
    }
}

@media (orientation: landscape) and (min-width: 1040px) {
    .embla__slide {
        flex: 0 0 25%;
    }
}

.embla__slide.embla-slide-max {
    flex: 0 0 100%;
}

.embla__slide.has-loaded {
    opacity: 1;
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    border: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.4;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
}

.embla-prev-next-quiet,
.embla-prev-next-quiet:hover,
.embla-prev-next-quiet:focus {
    background-color: transparent;
    border: 1px solid transparent;
    width: 50px;
    height: 50px;
    border-radius: 78% 22% 34% 66% / 48% 33% 67% 52% !important;
    background-color: var(--utmb-color-white) !important;
}
@media (orientation: landscape) {
    .embla-prev-next-quiet,
    .embla-prev-next-quiet:hover,
    .embla-prev-next-quiet:focus {
        width: 90px !important;
        height: 90px !important;
    }
}

/* Pagination */

._embla__pagination {
    justify-content: center;
    margin-top: 20px;
}

.embla__dots,
.embla__dark__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 20px 0;
}

.embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 65px;
    height: 30px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
}

.embla__dot::after {
    background-color: var(--utmb-color-blend);
    width: 100%;
    height: 3px;
    border-radius: 2px;
    content: '';
}

.embla__dot.is-selected::after {
    background-color: var(--utmb-color-default);
    opacity: 1;
}

.embla__dark__dots > .embla__dot::after {
    background-color: var(--utmb-color-blue-light);
    width: 100%;
    height: 3px;
    border-radius: 2px;
    content: '';
    box-shadow: var(--utmb-text-shadows);
}

.embla__dark__dots > .embla__dot.is-selected::after {
    background-color: var(--utmb-color-white);
    opacity: 1;
}
