/*
Tip: most flex box issues are solved by applying min-width:0; to the offender.
*/

.flex {
    /* turn into a row, defauls as .main-start.cross-baseline */
    display: flex;
    position: relative;
    box-sizing: border-box;
}

.is-inline {
    display: inline-flex !important;
}

@media (orientation: landscape) {
    /* col-to-row on landscape */
    .flex-landscape {
        display: flex;
    }
}

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
    /* Do not execute col-to-row transformaton on mobiles with keyboard open */
    .flex-landscape {
        display: block;
    }
}

@media (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
    /* Do execute col-to-row transformaton on iPad Pro in portrait mode */
    .flex-landscape {
        display: flex;
    }
}

.col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.row-wrap {
    /* pair it with .**-gap */
    flex-wrap: wrap;
}

:root {
    --grid-gap: 10px;
}

@supports (display: grid) {
    /* .grid {
        --grid-gap: 10px;
        --grid-minmax: 300px;
        display: -ms-grid;
        display: grid;
        grid-gap: var(--grid-gap);
        grid-template-columns: repeat(auto-fit, minmax(var(--grid-minmax), 1fr));
        grid-auto-flow: dense;
    } */

    .is-grid-7 {
        grid-gap: 0 !important;
        grid-template-columns: repeat(7, 1fr);
    }
}

.grid-gap {
    grid-gap: 10px 10px;
}

.row-gap > *:not(:last-child) {
    --row-child-margin: 12px;
    margin-right: var(--row-child-margin);
}

.row-gap-xl > *:not(:last-child) {
    --row-child-margin: 22px;
    margin-right: var(--row-child-margin);
}

.col-gap > *:not(:last-child) {
    --column-child-margin: 6px;
    margin-bottom: var(--column-child-margin);
}

.col-gap-xl > *:not(:last-child) {
    --column-child-margin: 28px;
    margin-bottom: var(--column-child-margin);
}

.trinity {
    /* shortcut for .flex.main-between.row-gap-x */
    display: flex;
    justify-content: space-between;
}

.trinity::before,
.trinity::after,
.row-gap-x::before,
.row-gap-x::after {
    content: '';
}

.self-push-left {
    margin-left: auto;
}

.self-push-right {
    margin-right: auto;
}

.self-push-top {
    margin-top: auto;
}

.self-push-bottom {
    margin-bottom: auto;
}

.self-stretch {
    flex-grow: 1;
}
.self {
    flex: 2;
}

.self.self-small {
    flex: 1;
}

.self.self-large {
    flex: 3;
}

.self-unset {
    flex: 1;
    min-width: 0;
}

.main-start {
    justify-content: flex-start !important;
}

.main-end {
    justify-content: flex-end !important;
}

.main-center {
    justify-content: center !important;
}

.main-around {
    justify-content: space-around !important;
}

.main-between {
    justify-content: space-between !important;
}

.cross-center {
    align-items: center !important;
}

.cross-start {
    align-items: flex-start !important;
}

.cross-end {
    align-items: flex-end !important;
}

.cross-baseline {
    align-items: baseline !important;
}

.wrap-start {
    align-content: start !important;
}

.wrap-center {
    align-content: center !important;
}

.wrap-around {
    align-content: space-around !important;
}

.wrap-between {
    align-content: space-between !important;
}

.self-start {
    -ms-grid-row-align: start !important;
    align-self: flex-start !important;
}

.self-center {
    -ms-grid-row-align: center !important;
    align-self: center !important;
}

.self-end {
    -ms-grid-row-align: end !important;
    align-self: end !important;
    align-self: flex-end !important;
}

.self-stretch {
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
}

.self-none {
    flex: none !important;
    /* inflexible (w/h) => flex: 0 0 auto */
}
.self-no-grow {
    flex-grow: 0 !important;
}

@media (orientation: portrait) {
    .self-portrait-first {
        order: -1 !important;
    }
}
