@import 'common/common.scss';

// -----------------------------------------------------------------------------------------------------------------

//
// Specific Modifications for Mobile - PER COLUMN
//

.table_fix_Hour {
    @include bp(sm) {
        max-width: rem(54px);
    }
}

.table_fix_EventType {
    width: rem(120px) !important;
    flex: rem(120px) 0 auto !important;
    padding-right: 0 !important;
    margin-right: 0 !important;

    @include bp(xxs) {
        width: rem(150px) !important;
        flex: rem(150px) 0 auto !important;
        padding-right: rem(30px) !important;
        margin-right: rem(16px) !important;
    }

    @include bp(sm) {
        padding-right: rem(30px);
        align-items: center;
        display: flex;
    }

    @include bp(md) {
        width: rem(211px) !important;
        flex: rem(211px) 0 auto !important;
        margin-right: rem(32px) !important;
    }
}

// -----------------------------------------------------------------------------------------------------------------

//
// Columns - to be hidden on mobile in TABLE HEADER ROW
//

// ----------------------------------------------------
//
// Race Agenda Table

.table_fix_Itinerary {
    display: none !important;

    // Hide below rem(1280px)
    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_Location {
    display: none !important;

    // Hide below rem(1100px)
    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_City {
    display: none !important;

    @include bp(md) {
        display: block !important;
    }
}

// ----------------------------------------------------

// ----------------------------------------------------
//
// Runner Performance Table

.table_fix_Race {
    display: none !important;

    @include bp(sm) {
        display: block !important;
        padding-right: rem(20px);
    }

    @include bp(md) {
        padding-right: 0;
    }
}

.table_fix_Country {
    display: none !important;

    @include bp(md) {
        display: block !important;
    }
}

.table_fix_Distance {
    display: none !important;

    @include bp(lg) {
        display: block !important;
    }
}

.table_fix_MT {
    display: none !important;

    @include bp(lg) {
        display: block !important;
    }
}

.table_fix_Time {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_Speed {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_Rank {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_RankMain {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

// ----------------------------------------------------

// ----------------------------------------------------
//
// Rankings Table

.table_fix_Bib {
    display: none !important;

    @include bp(sm) {
        display: block !important;
    }
}

.table_fix_Name {
    padding-right: rem(50px);

    @include bp(sm) {
        padding-right: rem(20px);
    }

    // @media (min-width: rem(768px)) {
    //     padding-right: 0;
    // }
}

.table_fix_Team {
    display: none !important;

    @include bp(md) {
        display: block !important;
    }
}

.table_fix_Nat {
    display: none !important;

    @include bp(lg) {
        display: block !important;
    }
}

.table_fix_Cat {
    display: none !important;

    @include bp(lg) {
        display: block !important;
    }
}

.table_fix_RankCat {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_Diff {
    display: none !important;

    @include bp(xl) {
        display: block !important;
    }
}

.table_fix_Performance {
    display: none !important;

    @include bp(xxl) {
        display: block !important;
    }
}

// -----------------------------------------------------------------------------------------------------------------

//
// Dropdown Items - to be hidden / shown as same data is hidden / shown in normal table display
//

// ----------------------------------------------------
//
// Race Agenda Table

.table_tr_cd_item_Location {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_City {
    @extend .tr_cd_item_flex;

    @include bp(md) {
        display: none !important;
    }
}

.table_tr_cd_item_Itinerary {
    @extend .tr_cd_item_flex;
    margin: rem(30px) auto 0;
    max-width: rem(110px);

    @include bp(xl) {
        display: none !important;
    }
}

// ----------------------------------------------------

// ----------------------------------------------------
//
// Runner Performance Table

.table_tr_cd_item_Race {
    @extend .tr_cd_item_flex;

    @include bp(sm) {
        display: none !important;
    }
}

.table_tr_cd_item_Country {
    @extend .tr_cd_item_flex;

    @include bp(md) {
        display: none !important;
    }
}

.table_tr_cd_item_Distance {
    @extend .tr_cd_item_flex;

    @include bp(lg) {
        display: none !important;
    }
}

.table_tr_cd_item_MT {
    @extend .tr_cd_item_flex;

    @include bp(lg) {
        display: none !important;
    }
}

.table_tr_cd_item_Time {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_Speed {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_Rank {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_RankMain {
    @extend .tr_cd_item_flex;
    max-width: unset !important;

    @include bp(xl) {
        display: none !important;
    }
}

// ----------------------------------------------------
//
// Rankings Table

.table_tr_cd_item_Bib {
    @extend .tr_cd_item_flex;

    @include bp(sm) {
        display: none !important;
    }
}

.table_tr_cd_item_Team {
    @extend .tr_cd_item_flex;

    @include bp(md) {
        display: none !important;
    }
}

.table_tr_cd_item_Nat {
    @extend .tr_cd_item_flex;

    @include bp(lg) {
        display: none !important;
    }
}

.table_tr_cd_item_Cat {
    @extend .tr_cd_item_flex;

    @include bp(lg) {
        display: none !important;
    }
}

.table_tr_cd_item_RankCat {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_Diff {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.table_tr_cd_item_Performance {
    @extend .tr_cd_item_flex;
    margin: rem(30px) auto 0;
    max-width: 100%!important;

    @include bp(xxl) {
        display: none !important;
    }
}

// ----------------------------------------------------

.table_tr_cd_item {
    @extend .tr_cd_item_flex;

    @include bp(xl) {
        display: none !important;
    }
}

.tr_cd_item_flex {
    display: block;
    flex-direction: row;
    place-items: center;
    justify-content: center;


    @include bp(sm) {
        display: flex;
    }
}

// -----------------------------------------------------------------------------------------------------------------
