@import '../../styles/common/common.scss';

//
// Sizing Version 1.0
//
// TO REPLACE by version 2.0 and refactor components that still use 1.0
//

.spacer-xs {
    min-height: rem(8px);

    @include bp(lg) {
        min-height: rem(8px);
    }
}

.spacer-s {
    min-height: rem(12px);

    @include bp(lg) {
        min-height: rem(12px);
    }
}

.spacer-default {
    min-height: rem(16px);

    @include bp(lg) {
        min-height: rem(16px);
    }
}

.spacer-m {
    min-height: rem(10px);

    @include bp(lg) {
        min-height: rem(20px);
    }
}

.spacer-l {
    min-height: rem(35px);

    @include bp(lg) {
        min-height: rem(56px);
    }
}

.spacer-xl {
    min-height: rem(45px);

    @include bp(lg) {
        min-height: rem(75px);
    }
}

.spacer-xxl {
    min-height: rem(60px);

    @include bp(lg) {
        min-height: rem(100px);
    }
}

//
// Sizing Version 2.0
//

.spacer-10 {
    min-height: rem(15px);

    @include bp(lg) {
        min-height: rem(10px);
    }
}

.spacer-20 {
    min-height: rem(30px);

    @include bp(lg) {
        min-height: rem(20px);
    }
}

.spacer-30 {
    min-height: rem(35px);

    @include bp(lg) {
        min-height: rem(30px);
    }
}

.spacer-40 {
    min-height: rem(40px);

    @include bp(lg) {
        min-height: rem(40px);
    }
}

.spacer-50 {
    min-height: rem(50px);

    @include bp(lg) {
        min-height: rem(50px);
    }
}
