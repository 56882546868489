@import 'common.scss';

.markdown {
    h3 {
        font-size: rem(22px);
        font-family: var(--utmb-font-futura-heavy);
        line-height: rem(40px);

        @include bp(lg) {
            font-size: rem(30px);
        }

        @include bp(xxl) {
            font-size: rem(36px);
        }

        &:not(:first-child) {
            margin-top: rem(50px);
        }
    }

    p, ul, ol {
        margin: rem(20px) 0;
        font-size: rem(16px);

        @include bp(lg) {
            font-size: rem(18px);
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        padding-left: 2rem;
    }

    table {
        min-width: 100%;
        border-collapse: separate;
        font-family: var(--utmb-font-futura-light);
        margin-bottom: 2rem;
        table-layout: fixed;
        thead {
            background-color: var(--utmb-color-primary);
            color: var(--utmb-color-default);
            font-family: Oxanium;
            th {
                padding: 5px;
                text-transform: capitalize;
                white-space: nowrap;
            }
        }
        tbody {
            tr {
                background-color: rgba(#cccccc, 0.3);
                color: var(--utmb-color-primary);
                &:nth-child(odd) {
                    background-color: transparent;
                }
                td {
                    padding: 2px 5px;
                    min-width: 120px;
                }

                &:hover {
                    background-color: var(--utmb-color-secondary);
                }
            }
        }

    }

    &.primaryColorIsDark table {
        thead {
            color: var(--utmb-color-white);
        }
    }

    .table-container {
        overflow-x: auto;
        width: 95vw;

        @include bp(lg) {
            width: 100%;
        }
    }
}
