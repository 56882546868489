:root {
    --reach-menu-button: 1;
    --popover-offset: 6px;
}
.drop-down {
    max-width: 100%;
    position: relative;
}

[data-reach-menu],
[data-reach-menu-popover] {
    top: calc(44px + var(--popover-offset)) !important;
}

[data-reach-menu-button] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: inherit;
    background-color: transparent;
    border: 1px solid transparent;
}

[data-reach-menu-item] {
    display: flex;
    user-select: none;
    cursor: pointer;
    /* a */
    color: inherit;
    font: inherit;
    text-decoration: initial;

    /* both */
    min-height: 44px;
    min-width: 0;
}

[data-reach-menu-items]:focus {
    outline: none !important;
    border-radius: 0 !important;
}

[data-reach-menu][hidden],
[data-reach-menu-popover][hidden] {
    display: none;
}

.drop-down.is-horizontal [data-reach-menu-button] {
    max-width: 48px;
}

.drop-down.is-horizontal [data-reach-menu-popover] {
    top: 0px !important;
    right: 48px;
    width: calc(100% - 48px);
    background-color: transparent;
}

.drop-down.is-horizontal [data-reach-menu-items] {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}
