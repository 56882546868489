/// Font size in pixels used to calcul REM.
$body-context: 10px !default;
$gutter-x: 30px !default;

// My UTMB
$transition-duration: 0.6s !default;
$transition-property: all !default;
$transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19) !default;
$transition: $transition-property $transition-duration $transition-timing-function !default;
// Menu
$menu-thin-at: 'lg' !default;
$menu-normal-at: 'xl' !default;
$menu-normal-width: 300px !default;
$menu-item-height: 80px !default;
$menu-item-padding-x: 30px !default;
$menu-item-icon-width: 20px !default;
$menu-item-icon-expand-height: 2px !default;
$menu-closed-width: $menu-item-padding-x * 2 + $menu-item-icon-width !default;

// Colors
$color-category-general: #00dbff;
$color-category-20k: #ffff00;
$color-category-50k: #fc5a00;
$color-category-100k: #7ddf65;
$color-category-100m: #f42525;

:export {
    basePixels: $body-context;

    // Colors
    color: {
        category-general: $color-category-general;
        category-20k: $color-category-20k;
        category-50k: $color-category-50k;
        category-100k: $color-category-100k;
        category-100m: $color-category-100m;
    }

    // // Colors
    // colors: {
    // default: $utmb-color-default;
    // // And so on
    // }
}
