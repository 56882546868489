@import '../../styles/common/common.scss';

:root {
    --h0: 12vmax;
    --h1: calc(math.div(42, 16) * 1rem);
    --h2: calc(math.div(36, 16) * 1rem);
}

// ----------------------------------------------------------
//
// Font Colors
//
//

.font-white {
    color: var(--utmb-color-white);
}

// ----------------------------------------------------------
//
// Font Utils
//
//

.font-number {
    font-variant-numeric: tabular-nums;
}

.font-center {
    text-align: center !important;
}

.font-left {
    text-align: left !important;
}

.font-right {
    text-align: right !important;
}

.font-traced {
    -webkit-text-stroke: rem(2px) white;
    -webkit-text-fill-color: transparent;
}

.font-uppercase {
    text-transform: uppercase;
}

.font-lowercase {
    text-transform: lowercase;
}

.font-capitalise {
    text-transform: capitalize;
}

// ----------------------------------------------------------
//
// Font Effects
//
//

.has-blend {
    mix-blend-mode: overlay; /* difference | multiply*/
}

// Make the blended title more visible on subpages
.has-blend-subpage {
    filter: brightness(1.5);
}

@-moz-document url-prefix() {
    .has-blend {
        background: none;
        text-shadow: none;
    }
}

/* IE 10 */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .has-blend {
        background: none;
        text-shadow: none;
    }
}

.has-gradient {
    background: linear-gradient(to right, darkblue, darkorchid);
    color: transparent;
    background-clip: text;
}

// ----------------------------------------------------------
//
// Font Families
//
//

$cdn-url: 'https://res.cloudinary.com/utmb-world/raw/upload/assets/font';

@font-face {
    font-family: 'Futura PT';
    src: url('#{$cdn-url}/FuturaPTHeavyOblique.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTHeavyOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('#{$cdn-url}/FuturaPTBookOblique.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTBookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('#{$cdn-url}/FuturaPTMedium.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('#{$cdn-url}/FuturaPTMediumOblique.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTMediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('#{$cdn-url}/FuturaPTBoldOblique.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTBoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Light';
    font-style: normal;
    font-weight: normal;
    src: url('#{$cdn-url}/FuturaPTLight.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTLight.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    font-style: normal;
    font-weight: normal;
    src: url('#{$cdn-url}/FuturaPTBook.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTBook.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Bold';
    font-style: normal;
    font-weight: normal;
    src: url('#{$cdn-url}/FuturaPTBold.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTBold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Heavy';
    font-style: normal;
    font-weight: normal;
    src: url('#{$cdn-url}/FuturaPTHeavy.woff2') format('woff2'), url('#{$cdn-url}/FuturaPTHeavy.woff') format('woff');
    font-display: swap;
}

.futura-heavy {
    font-family: 'Futura PT Heavy';
    font-weight: normal;
    font-style: normal;
}

.futura-heavy-italic {
    font-family: 'Futura PT';
    font-weight: 900;
    font-style: italic;
}

.futura-book {
    font-family: 'Futura PT Book';
    font-weight: normal;
    font-style: normal;
}

.futura-book-italic {
    font-family: 'Futura PT';
    font-weight: normal;
    font-style: italic;
}

.futura-bold {
    font-family: 'Futura PT Bold';
    font-weight: bold;
    font-style: normal;
}

.futura-bold-italic {
    font-family: 'Futura PT';
    font-weight: bold;
    font-style: italic;
}

.futura-medium {
    font-family: 'Futura PT';
    font-weight: 500;
    font-style: normal;
}

.futura-medium-italic {
    font-family: 'Futura PT';
    font-weight: 500;
    font-style: italic;
}

.futura-light-italic {
    font-family: 'Futura PT';
    font-weight: 300;
    font-style: italic;
}

.futura-light {
    font-family: 'Futura PT Light';
    font-weight: 300;
    font-style: normal;
}

.font-oxanium-light {
    font-family: 'Oxanium';
    font-weight: 300;
    font-style: normal;
}

.font-oxanium-regular {
    font-family: 'Oxanium';
    font-weight: 400;
    font-style: normal;
}

.font-oxanium-medium {
    font-family: 'Oxanium';
    font-weight: 500;
    font-style: normal;
}

.font-oxanium-bold {
    font-family: 'Oxanium';
    font-weight: 700;
    font-style: normal;
}

// ----------------------------------------------------------
//
// Font Sizes
//
// ----------------------------------------------------------

// Mobile Sizes

$sizes: 100;

@for $i from 1 through $sizes {
    .font-#{$i} {
        font-size: rem($i);
    }
}

// -----------------------------

// Desktop Sizes

@for $i from 1 through $sizes {
    .font-d-#{$i} {
        @include bp(lg) {
            font-size: rem($i);
        }
    }
}

// ----------------------------------------------------------
//
// Line Heights
//
// ----------------------------------------------------------

// Mobile

$sizes: 100;

@for $i from 1 through $sizes {
    .lh-#{$i} {
        line-height: rem($i);
    }
}

// -----------------------------

// Desktop

@for $i from 1 through $sizes {
    .lh-d-#{$i} {
        @include bp(lg) {
            line-height: rem($i);
        }
    }
}

// ----------------------------------------------------------
//
// Letter Spacing
//
// ----------------------------------------------------------

$sizes: 5;

.ls-05 {
    letter-spacing: 0.5px;
}

@for $i from 1 through $sizes {
    .ls-#{$i} {
        letter-spacing: rem($i);
    }
}

// ----------------------------------------------------------
//
// Font Weight
//
// ----------------------------------------------------------

$sizes: 9;

@for $i from 1 through $sizes {
    .fw-#{$i} {
        font-weight: #{$i} + '00';
    }
}
